.login {
    /* background-image: linear-gradient(rgb(42, 42, 42), rgb(21, 21, 21)); */
    background-color: rgb(42, 42, 42);
    min-height: 100vh;
    min-width: 100vw;
    display: flex;
    justify-content: center;
}

.login-headline {
    font-size: 60px;
    text-align: center;
    color:white;
    margin-top: 0px;
}

.login-modal {
    background-color: #c31414;
    text-align: center;
    max-width: 80%;
    width: 500px;
    max-height: 60%;
    height: 500px;
    border-radius: 30px;
    box-shadow:rgb(0, 0, 0) 30px 30px;
    
    position: absolute;
    animation: float 15s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes float {
    0% {
        top: 15%;
    }
    50% {
        top: 25%;
    }
    100% {
        top: 15%;
    }
}

@keyframes pendulum {
    0% {
        top: 15%;
        box-shadow:rgb(0, 0, 0) 30px -30px;
    }
    50% {
        top: 25%;
        box-shadow:rgb(0, 0, 0) 30px 30px;
    }
    100% {
        top: 15%;
        box-shadow:rgb(0, 0, 0) 30px -30px;
    }
}

.login-title {
    font-size: 2.1em;
    font-weight: 900;
    color: white;
    padding: 10px;
    background-image: linear-gradient(red, #c31414);
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
}

.login-form {
    background-color: rgb(156, 156, 156);
    margin: 10px;

    height: 80%;
    border-radius: 30px;
}

.login-form-item {
    padding-top: 25px;
    padding-right: 25px;
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    
}

.login-form-item-label {
    color: white;
    font-weight: 900;
    margin-left: 5%;
}

.login-form-item-text {
    width: 60%;
    height: 20px;
    border-radius: 5px;
}

.login-submit{
    color: rgb(252, 255, 215);
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 5px;
    padding-bottom: 5px; 
    margin-top: 10px;
    text-decoration: none;
    font-size: 20px;
    font-weight: 900;
    background-image: linear-gradient(rgb(244, 225, 16), rgb(175, 150, 24));
    border: 4px solid black;
    border-radius: 50px;
    position: relative;
    top: 60px;
}
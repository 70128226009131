.navbar{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
}

.navbar-item{
    padding-left: 20px;
    padding-right: 0px;
    font-weight: 900;
    color: white;
}

.navbar-playSection{
    overflow: hidden;
    border-radius: 50px;
    /* padding-left: 20px;
    padding-right: 20px;*/
    padding-top: 3px;
    padding-bottom: 3px; 
    background-image: linear-gradient(rgb(244, 225, 16), rgb(175, 150, 24));
    border-width: 4px;
    border-style: solid;
    border-top-color: rgb(90, 9, 9);/*rgb(255, 65, 65);*/
    border-left-color: rgb(90, 9, 9);
    border-bottom-color: rgb(90, 9, 9);
    border-right-color: rgb(90, 9, 9);
}

.navbar-playButton{
    color: rgb(252, 255, 215);
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 3px;
    padding-bottom: 3px; 
    text-decoration: none;
    font-size: 20px;
    position: relative;
    overflow: hidden;
}

.navbar-playButton::before{
    content: '';
    position: absolute;
    background-color: rgba(255,255,255,.5);
    height: 80px;
    width: 80px;
    top: -23px;
    left: -100px;
    transform: skewX(30deg);
    animation: glimmer 1.5s;
    animation-iteration-count: infinite;
}

.navbar-playButton:active::before{
}

@keyframes glimmer {
    0% {
        left: -200%;
    }
    100% {
        left: 200%;
    }
}
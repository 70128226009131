.header{
    color: white;
    display: flex;
    align-items: center;
    background-image: linear-gradient(red, #c31414);
    padding-top: 5px;
    padding-bottom: 5px;
    position: fixed;
    top: 0;
    width: 100%
}

.header-logo{
    font-size: 36px;
    font-weight: 900;
    padding-left: 10px;
    width: 100%;
    flex: 1;
    
}

.header-link{
    color:rgb(247, 247, 247);
    text-decoration: none;
}

.header-navbar{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-right: 10px;
    flex: 9;
}